import { useEffect, useState } from "react";

// Components
import AppPageTemplate from "../../Components/AppPageTemplate/AppPageTemplate";
import Input from "../../Components/Input/Input";
import InputCalendar from "../../Components/InputCalendar/InputCalendar";
import ImageUploader from "../../Components/ImageUploader/ImageUploader";
import { Checkbox } from "primereact/checkbox";

// pop up library
import Swal from 'sweetalert2';

// db connection
import SteinStore from "stein-js-client";

//Styles
import "./NuevoAsesor.scss"

//Random id
import { v4 as uuid } from "uuid";


export default function ({ }) {
  //importamos Stein para la base de datos
  const AsesoresDB = new SteinStore(
    "https://api.steinhq.com/v1/storages/661deda64a642363121cafb2"
  );

  //campos
  const [name, setName] = useState("")
  const [cua, setCua] = useState("")
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [date, setDate] = useState("")
  const [adressCalle, setAdressCalle] = useState("")
  const [adressColonia, setAdressColonia] = useState("")
  const [adressEstado, setAdressEstado] = useState("")
  const [medallas, setMedallas] = useState([]);

  //variables 
  const [image, setImage] = useState(null);

  //condicionales para funcionamiento de enviar formulario
  const [enviarformulario, setEnviarFormulario] = useState(false) // 1
  const [enviandoformulario, setEnviandoFormulario] = useState(false) // 2










  // funciones
  const onIngredientsChange = (e) => {
    let _medallas = [...medallas];

    if (e.checked)
      _medallas.push(e.value);
    else
      _medallas.splice(_medallas.indexOf(e.value), 1);

    setMedallas(_medallas);
  }
  function fechaString(fecha) {
    const fechaObj = new Date(fecha);
    const opciones = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const fechaFormateada = fechaObj.toLocaleDateString('es-ES', opciones);
    return fechaFormateada;
  }
  // función de enviar formulario a base de datos
  const handleEnviarFormulario = (e) => {
    setEnviandoFormulario(true)
  }













  //useEffects
  useEffect(() => {
    if (
      name !== "" &&
      username !== "" &&
      password !== "" &&
      phone !== "" &&
      email !== "" &&
      date !== ""
    ) {
      setEnviarFormulario(true)
    } else {
      setEnviarFormulario(false)
      // console.log("check values:",[
      //   name,
      //   cua,
      //   phone,
      //   email,
      //   date,
      //   adressCalle,
      //   adressColonia,
      //   adressEstado,
      //   medallas
      // ]);
    }
  }, [
    name,
    cua,
    username,
    password,
    phone,
    email,
    date,
    adressCalle,
    adressColonia,
    adressEstado,
    medallas
  ])











  useEffect(() => {
    if (enviandoformulario) {
      const loadingSwal = Swal.fire({
        title: 'Enviando registro...',
        text: 'Por favor espere un momento',
        icon: 'info',
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showConfirmButton: false,
        showCloseButton: false,
        didOpen: () => {
          Swal.showLoading();
        }
      })


      const DatosDelAsesor = {
        "uuid": `${uuid()}`,
        "cua": cua,
        "nombre": name,
        "usuario_crm": username,
        "clave_crm": password,
        "telefono": phone,
        "correo": email,
        "nacimiento": fechaString(date),
        "address_calle": adressCalle,
        "address_colonia": adressColonia,
        "address_estado": adressEstado,
        "medalla_vida": medallas.includes("medalla_vida") ? 1 : 0,
        "medalla_gmm": medallas.includes("medalla_gmm") ? 1 : 0,
        "medalla_promedio25": medallas.includes("medalla_promedio25") ? 1 : 0,
        "medalla_acompanador": medallas.includes("medalla_acompanador") ? 1 : 0,
        "medalla_activo": medallas.includes("medalla_activo") ? 1 : 0,
        "medalla_congresista": medallas.includes("medalla_congresista") ? 1 : 0,
        "medalla_cabos": medallas.includes("medalla_cabos") ? 1 : 0,
        "medalla_noveles": medallas.includes("medalla_noveles") ? 1 : 0,
      }


      AsesoresDB
        .append("Asesores-CRM-Valoret", [DatosDelAsesor], {
          authentication: {
            username: "crm-valoret", password: "crm-valoret587943687953470985342879070953428687253487092538709523496780267"
          }
        })
        .then(res => {
          loadingSwal.close()
          Swal.fire({
            title: 'Registro enviado con éxito',
            icon: 'success',
            showConfirmButton: false,
            showCloseButton: false,
            iconColor: "#0F70D4",
            html: `
              <div class="afterPost">
                <a href="." class="btn">Enviar otro</a>
                <a href="https://docs.google.com/spreadsheets/d/1A_caLrkSh6XzwffrvfQB-9kx-ela63NTY3L6_FI-Tok/edit?usp=sharing" class="btn">Ver registros</a>
              </div>
            `
          })
        })
        .catch(error => {
          console.error(error)
          setEnviandoFormulario(false)
        });
    }
  }, [enviandoformulario])






  useEffect(() => {
    console.log('image', image);
  }, [image])






  return (<>
    <AppPageTemplate title={"Nuevo Asesor"}>
      <ImageUploader setImage={setImage} />
      <div style={{ height: "30px" }} />

      <h2 className="h2">Nombre completo</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={name}
        onChange={e => setName(e.target.value)}
      />
      <div style={{ height: "30px" }} />


      <h2 className="h2">CUA (Opcional)</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"number"}
        pattern="[0-9]*"
        value={cua}
        onChange={e => setCua((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <div style={{ height: "30px" }} />






      <h2 className="h2">Usuario para el CRM</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={username}
        onChange={e => setUsername(e.target.value)}
      />
      <div style={{ height: "30px" }} />








      <h2 className="h2">Clave para el CRM</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <div style={{ height: "30px" }} />











      <h2 className="h2">Telefono</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"number"}
        pattern="[+]?[0-9]*"
        value={phone}
        onChange={e => setPhone((v) => (e.target.validity.valid ? e.target.value : v))}
      />
      <div style={{ height: "30px" }} />









      <h2 className="h2">Correo electrónico</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <div style={{ height: "30px" }} />







      <h2 className="h2">Fecha de nacimiento</h2>
      <div style={{ height: "10px" }} />
      <InputCalendar
        value={date}
        onChange={e => setDate(e.value)}
      />
      <div style={{ height: "30px" }} />









      <h2 className="h2">Dirección</h2>
      <div style={{ height: "10px" }} />
      <Input
        type={"text"}
        placeholder={"Calle"}
        value={adressCalle}
        onChange={e => setAdressCalle(e.target.value)}
      />
      <div style={{ height: "20px" }} />
      <Input
        type={"text"}
        placeholder={"Colonia"}
        value={adressColonia}
        onChange={e => setAdressColonia(e.target.value)}
      />
      <div style={{ height: "20px" }} />
      <Input
        type={"text"}
        placeholder={"Estado"}
        value={adressEstado}
        onChange={e => setAdressEstado(e.target.value)}
      />
      <div style={{ height: "30px" }} />










      <h2 className="h2">Medallas</h2>
      <div style={{ height: "10px" }} />

      <div className="Checkbox">
        <Checkbox inputId="ingredient5" name="medalla_vida" value="medalla_vida" onChange={onIngredientsChange} checked={medallas.includes('medalla_vida')} />
        <label htmlFor="ingredient5" className="ml-2">🍀 Bonos vida</label>
      </div>
      <div style={{ height: "10px" }} />

      <div className="Checkbox">
        <Checkbox inputId="ingredient1" name="medalla_gmm" value="medalla_gmm" onChange={onIngredientsChange} checked={medallas.includes('medalla_gmm')} />
        <label htmlFor="ingredient1" className="ml-2">🩺 Bonos GMM</label>
      </div>
      <div style={{ height: "10px" }} />


      <div className="Checkbox">
        <Checkbox inputId="ingredient2" name="medalla_promedio25" value="medalla_promedio25" onChange={onIngredientsChange} checked={medallas.includes('medalla_promedio25')} />
        <label htmlFor="ingredient2" className="ml-2">⤴️ 25 Puntos Promedio</label>
      </div>
      <div style={{ height: "10px" }} />


      <div className="Checkbox">
        <Checkbox inputId="ingredient3" name="medalla_acompanador" value="medalla_acompanador" onChange={onIngredientsChange} checked={medallas.includes('medalla_acompanador')} />
        <label htmlFor="ingredient3" className="ml-2">🫱🏻‍🫲🏻 Agente acompañador</label>
      </div>
      <div style={{ height: "10px" }} />

      <div className="Checkbox">
        <Checkbox inputId="ingredient6" name="medalla_activo" value="medalla_activo" onChange={onIngredientsChange} checked={medallas.includes('medalla_activo')} />
        <label htmlFor="ingredient6" className="ml-2">🌐 Agente Activo</label>
      </div>
      <div style={{ height: "10px" }} />

      <div className="Checkbox">
        <Checkbox inputId="ingredient4" name="medalla_congresista" value="medalla_congresista" onChange={onIngredientsChange} checked={medallas.includes('medalla_congresista')} />
        <label htmlFor="ingredient4" className="ml-2">🥇 Congresista</label>
      </div>
      <div style={{ height: "10px" }} />

      <div className="Checkbox">
        <Checkbox inputId="ingredient7" name="medalla_cabos" value="medalla_cabos" onChange={onIngredientsChange} checked={medallas.includes('medalla_cabos')} />
        <label htmlFor="ingredient7" className="ml-2"> 🏖️ Viaje a Los Cabos</label>
      </div>
      <div style={{ height: "10px" }} />

      <div className="Checkbox">
        <Checkbox inputId="ingredient8" name="medalla_noveles" value="medalla_noveles" onChange={onIngredientsChange} checked={medallas.includes('medalla_noveles')} />
        <label htmlFor="ingredient8" className="ml-2">🎓 Seminario Noveles</label>
      </div>
      <div style={{ height: "10px" }} />
    </AppPageTemplate>

    {
      enviarformulario ?
        <button className="PiButton" onClick={(e) => handleEnviarFormulario(e)}><i className="pi pi-send"></i></button>
        :
        <button className="PiButton PiButtonDisabled"><i className="pi pi-send"></i></button>
    }
  </>);
}